<template><v-app><Header /><v-main><v-container fluid>
<v-row align="center" justify="center" v-show="$store.state.showSearchForm">
<v-col cols="12" md="6">
<v-card elevation="2" outlined>
    <v-card-title>{{ $t('Catalog search') }}</v-card-title>
    <v-card-text>
        <v-row>
            <v-col cols="12" md="3">
                <v-combobox v-model="cmbsearcvalue" :items="comboboxsearch" item-text="text" item-value="val" outlined dense></v-combobox>
            </v-col>
            <v-col cols="12" md="5">
                <v-text-field :placeholder="$t('Search')" outlined dense append-icon="mdi-magnify" @keyup.enter="searchClick()" @click:append="searchClick()" v-model="textsearch"></v-text-field>
            </v-col>            
            <v-col cols="12" md="4">
                <v-combobox v-model="bahanValue" :items="bahans" :label="$t('Bahan')" @change="searchClick()" outlined dense />
            </v-col>            
        </v-row>
    </v-card-text>
</v-card>
</v-col>
</v-row>

<v-row class="d-none d-md-flex">
<v-col cols="12" md="3" v-for="(katalog, index) in katalogs" :key="katalog.id" :id="index">

<v-card elevation="2" outlined height="350">
    <v-card-title>{{ katalog.title }}</v-card-title>
    <v-card-text>
        <v-row>
            <v-col cols="12" md="3"><v-img :src="katalog.cover_url"/></v-col>
            <v-col cols="12" md="9">
                <v-row><v-col cols="5">{{ $t('Material type') }}</v-col><v-col cols="7">{{ katalog.jenis_bahan }}</v-col></v-row>
                <v-row><v-col cols="5">{{ $t('Publisher') }}</v-col><v-col cols="7">{{ katalog.penerbit }}</v-col></v-row>
                <v-row><v-col cols="5">{{ $t('Author') }}</v-col><v-col cols="7">{{ katalog.pengarang }}</v-col></v-row>
                <v-row v-show="katalog.file_extensions.length > 0"><v-col cols="5">{{ $t('Digital content') }}</v-col><v-col cols="7">{{ katalog.file_extensions }}</v-col></v-row>
            </v-col>
        </v-row>
    </v-card-text>
    <v-card-actions style=" position: absolute;  bottom: 0; right:0;">
        <a :href="'/' + katalog.id + '/katalog-detail'" style="text-decoration:none;"><v-btn dark color="green">{{ $t('Detail') }}</v-btn></a>
    </v-card-actions>
</v-card>
</v-col>
</v-row>

<v-row class="d-md-none">
<v-col cols="12" md="3" v-for="(katalog, index) in katalogs" :key="katalog.id" :id="index">
<v-card elevation="2" outlined>
    <v-card-title>{{ katalog.title }}</v-card-title>
    <v-card-text>
        <v-row>
            <v-col cols="12" md="3"><v-img :src="katalog.cover_url"/></v-col>
            <v-col cols="12" md="9">
                <v-row><v-col cols="5">{{ $t('Material type') }}</v-col><v-col cols="7">{{ katalog.jenis_bahan }}</v-col></v-row>
                <v-row><v-col cols="5">{{ $t('Publisher') }}</v-col><v-col cols="7">{{ katalog.penerbit }}</v-col></v-row>
                <v-row><v-col cols="5">{{ $t('Author') }}</v-col><v-col cols="7">{{ katalog.pengarang }}</v-col></v-row>
                <v-row v-show="katalog.file_extensions.length > 0"><v-col cols="5">{{ $t('Digital content') }}</v-col><v-col cols="7">{{ katalog.file_extensions }}</v-col></v-row>
            </v-col>
        </v-row>
    </v-card-text>
    <v-card-actions class="justify-end">
        <a :href="'/' + katalog.id + '/katalog-detail'" style="text-decoration:none;"><v-btn color="green">{{ $t('Detail') }}</v-btn></a>
    </v-card-actions>
</v-card>
</v-col>
</v-row>

<v-row>
<v-col cols="12">
<v-pagination v-show="katalogs.length > 0" v-model="katalogpaging.page" :length="katalogpaging.length" :total-visible="katalogpaging.visible" @input="refreshKatalogTable()" />
</v-col>
</v-row>
      
</v-container></v-main><Footer /></v-app></template>
<script>
import Header from './layout/Header';
import Footer from './layout/Footer';

  export default {
    components: { Header, Footer },
    data: vm => ({
        ajaxProcess:false,
        katalogs:[],
        comboboxsearch:[{text:vm.$t('Title'), val:'title'}, {text:vm.$t('Publisher'), val:'publisher'}, {text:vm.$t('Author'), val:'author'}, {text:'*', val:'*'}], cmbsearcvalue:{text:'*', val:'*'},textsearch:'',
        katalogpaging:{page:1,visible:6,length:0},
        bahanValue:'',bahans:[],
    }),
    methods: {
        refreshKatalogTable() {
            var vue = this;
            
            var parameters = {paging: 'a', page: vue.katalogpaging.page};
            
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };
            
            if (vue.$store.state.showSearchForm) {
                parameters.type = vue.cmbsearcvalue.val;
                parameters.text = vue.textsearch;
                parameters.bahan = vue.bahanValue.value;
            }
            requestOptions.body = JSON.stringify(parameters);
            
            vue.ajaxProcess = true;
            fetch(process.env.VUE_APP_WEB_API + 'api/katalog-table', requestOptions)
            .then(async response => {
                var responseResult = await response.json();
                vue.katalogs = responseResult.catalogs;
                vue.katalogpaging.length = responseResult.length;
                vue.ajaxProcess = false;
            })
            .catch(() => {
                window.location.replace("/logout");
            });
        },
        searchClick() {
            this.katalogpaging.page = 1;
            this.refreshKatalogTable();
        },
        addBahan() {
            var vue = this;
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };
            fetch(process.env.VUE_APP_WEB_API + 'api/katalog-bahan-options', requestOptions)
            .then(async response => {
                var results = await response.json();
                var optionAll = {value: -1, text: vue.$t('Semua Bahan')};
                vue.bahans.push(optionAll);
                results.forEach(function(val) {
                    vue.bahans.push({value: val.id, text: val.name});
                });
                vue.bahanValue = optionAll;
            })
            .catch(() => {
                window.location.replace("/logout");
            });
        }
    },
    mounted() {
        this.addBahan();
        this.refreshKatalogTable();
    }
  }
</script>
